<template>
  <v-app-bar color="white" dense flat rounded>
    <v-tabs fixed-tabs background-color="white" class="primary--text bold--text">
      <v-tab v-for="link in links" :key="link.name" @click="routeTo(link)" optional active-class="color:blue">{{
        link.name
      }}</v-tab>
      <!-- <v-tab v-for="link in links" :key="link.name" router :to="routeTo(link)" optional >
         {{link.name
      }}
       </v-tab> -->
    </v-tabs>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppNavigation",
  data() {
    return {
      links: [
        {
          name: "Trainer Profile",
          path: "/pages/createTrainer",
          editModeName: "trainerProfile"
        },
        {
          name: "Admin Console",
          path: "/pages/adminConsole",
          editModeName: "adminConsole"
        },
        {
          name: "Original Files",
          path: "/pages/originalFiles",
          editModeName: "originalFiles"
        },
        {
          name: "Payment Details",
          path: "/pages/paymentDetails",
          editModeName: "paymentDetails"
        },
        { name: "Legal", path: "/pages/legal", editModeName: "legal" }
        // {name:"LinkedIn Raw Data" ,path:"/pages/linkedInRaw"},
        // {name:"Dashboard", path:"/pages/dashboard"}
      ]
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      editMode: "editMode"
    })
  },
  methods: {
    routeTo(link) {
        console.log('link -----------',this.$router.currentRoute.fullPath);
      console.log("link -----------",link);
      let routeFurther = true;
      for (const [key, value] of Object.entries(this.editMode)) {
        console.log(`${key}: ${value}`);
        if (this.editMode[key]) {
          routeFurther = false;
        }
      }
      if (!routeFurther) {
        console.log("do not route ");
        this.$store.dispatch("project/manageAlertBox", {
          status: true,
          text: "Save the data before proceeding ahead",
          headerText: "Alert",
          afterOk: () => {}
        });
      
       // break;
        //return this.$router.currentRoute.fullPath;
      } else {
        console.log("route ");
        //return link.path
       this.$router.push(link.path);
      }
      // if edit mode is true dono route and show an alert box
    }
  }
};
</script>

<style></style>
